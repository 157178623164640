.conferenceImg {
  float: right;
  width: 20rem;
  margin-left: 1rem;
}

/* Screen sizes */
@media screen and (max-width: 45rem) {
  .conferenceImg {
    width: 10rem;
  }
}
