.ailearnersPageContainer {
  padding: 2rem;
  max-width: 70rem;
}

.logo {
  width: 20rem;
  margin-bottom: 1rem;
}

.LaraImg {
  width: 20rem;
  margin-left: 3rem;
  margin-right: 3rem;
  float: right;
}

.gameImgsContainer {
  display: flex;
  flex-direction: row;
}

.gameImg {
  flex: 30% 1;
  width: 30%;
  margin: 0.5rem 2rem 1rem 0rem;
}

/* Screen sizes */
@media screen and (max-width: 68rem) {
  .LaraImg {
    width: 15rem;
    height: 19rem;
  }
}

@media screen and (max-width: 35rem) {
  .aboutContainer {
    display: flex;
    flex-direction: column;
  }

  .LaraImg {
    margin: 1rem 3rem 1rem 2rem;
  }

  .gameImgsContainer {
    flex-direction: column;
  }
}
