/* Style for the entire page container */
.aboutPageContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
}

/* Styling for the left side */
.imageSectionContainer {
  width: 20rem;
  display: flex;
  flex-direction: column;
  padding: 2rem;
}

.AdeleImg {
  border-radius: 50%;
}

.underImageText {
  margin-top: 1rem;
  margin-left: 2rem;
}

.linksContainer {
  margin-top: 2.5rem;
  color: #33801f;
}

.email {
  color: black;
  text-decoration: none;
}
.email:hover {
  text-decoration: underline;
}

.profileLink {
  color: #33801f;
  text-decoration: none;
}
.profileLink:hover {
  text-decoration: underline;
}

/* Styling for the right side */
.mainTextContainer {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  max-width: 60rem;
  margin-left: 2rem;
}

.hikingImg {
  width: 500px;
  height: auto;
}

/* Screen sizes */
@media screen and (max-width: 68rem) {
  .imageSectionContainer {
    width: 15rem;
  }
}

@media screen and (max-width: 45rem) {
  .imageSectionContainer {
    width: 10rem;
  }

  .underImageText {
    margin-left: 0;
  }

  .mainTextContainer {
    margin-left: 0;
  }
}

@media screen and (max-width: 35rem) {
  .aboutPageContainer {
    flex-direction: column;
  }

  .imageSectionContainer {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: -2rem;
    padding: 0;
    padding-top: 1rem;
  }

  .AdeleImg {
    width: 80%;
  }

  .underImageText {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .linksContainer {
    margin-top: 1rem;
  }

  .decreaseMobileHeight {
    margin-top: 0rem;
  }

  .hikingImg {
    width: 90%;
  }
}
