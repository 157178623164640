.navbarContainer {
  width: 100%;
  height: 2.5rem;
  background-color: #1357a9;
  font-family: 'Inter';
}

.navbarItemsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 80%;
  float: right;
}

.navbarItem {
  color: white;
  text-decoration: none;
}

.navbarAdeleName {
  position: absolute;
  left: 0.5rem;
  margin-top: 0.5rem;
  font-size: 1.25rem;
  font-weight: 900;
}

/* Screen sizes */
/* On mobile, hide my name from the top left corner and just have the navbar */
@media screen and (max-width: 60rem) {
  .navbarItemsContainer {
    width: 100%;
  }
}
